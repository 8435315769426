<template>
  <div v-if="!showSpinner">
    <div class="row">
      <!-- Header Edit Technicage -->
      <div class="column w-100">
        <div class="card">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <p><b><u>Date de création: </u></b></p>
                </div>
                <div class="col-md-6">
                  {{ moment(edit_technicage.createdAt).format('DD/MM/YYYY') }}
                </div>
              </div>
            </div>
            <div class=" col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <p><b><u>Ref technicage:</u></b></p>
                </div>
                <div class="col-md-6">
                  {{ edit_technicage.ref}}
                </div>
              </div>
            </div>
            <div class=" col-md-4">
              <div class="row">
                <div class="col-md-5">
                  <p><b><u>Nombre de test à réaliser:</u></b> </p>
                </div>
                <div class="col-md-7">
                  {{ edit_technicage.nbTestToRealized}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <p><b><u>Date de modification:</u></b> </p>
                </div>
                <div class="col-md-8">
                  {{ moment(edit_technicage.updatedAt).format('DD/MM/YYYY') }}
                </div>
              </div>
            </div>
            <div class=" col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <p><b><u>Nom du technicage:</u></b> </p>
                </div>
                <div class="col-md-6">
                  <span v-if="!editNameTech">{{ edit_technicage.name}}&nbsp;
                    <i class="bx bx-edit" :style="getStyleIcon" @click="editNameTech = true"></i>
                  </span>
                  <span v-else>
                    <input type="text" class="form-control" id="orderDate" v-model="edit_technicage.name">
                    <i class="bx bx-check" :style="getStyleIcon" @click="editNameTech = false"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class=" col-md-4">
              <div class="row">
                <div class="col-md-5">
                  <p><b><u>Nombre de plaque:</u></b> </p>
                </div>
                <div class="col-md-7">
                  {{ edit_technicage.nbPlaque}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <p><b><u>Date de réalisation:</u></b></p>
                </div>
                <div class="col-md-6">
                  <span v-if="!editDateReal">{{ moment(edit_technicage.date_realisation).format('DD/MM/YYYY') }}&nbsp;
                    <i class="bx bx-edit" :style="getStyleIcon" @click="editDateReal = true"></i>
                  </span>
                  <span v-else>
                    <input type="date" class="form-control" id="orderDate" v-model="edit_technicage.date_realisation">
                    <i class="bx bx-check" :style="getStyleIcon" @click="editDateReal = false"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <p><b><u>Nom du technicien:</u></b> </p>
                </div>
                <div class="col-md-6">
                  <span v-if="!editTechnician">{{ edit_technicage.name_technician}} &nbsp;
                    <i class="bx bx-edit" :style="getStyleIcon" @click="editTechnician = true; "></i>
                  </span>
                  <span v-else>
                    <select v-model="technician" class="select2 form-select " id="laboratory" @change="changeTechnician()">
                      <option v-for="tech in allTechnician" :value="{id: tech.id, name: tech.firstname + ' ' + tech.lastname}">
                        {{ tech.lastname }} {{ tech.firstname }}
                      </option>
                    </select>
                    <i class="bx bx-check" :style="getStyleIcon" @click="editTechnician = false"></i>
                  </span>
                </div>
              </div>

            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-5">
                  <p><b><u>Statut:</u></b></p>
                </div>
                <div class="col-md-7">
                  {{ edit_technicage.status.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <p><b><u>Commentaire technicien:</u></b></p>
                </div>
                <div class="col-md-12">
                  <p><textarea class="form-control" type="text" v-model="edit_technicage.commentaireTechnician" :disabled="isBiologist"></textarea></p>
                  <button class="btn btn-info" @click="updateCommentaire" v-if="!isBiologist">Mettre à jour commentaire technicien</button>
                  <br/><span style="margin-left: 5px" v-if="messageUpdateCommentaireTechnicien !==  ''"><small>{{ messageUpdateCommentaireTechnicien }}</small></span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <p><b><u>Commentaire médecin biologiste:</u></b></p>
                </div>
                <div class="col-md-12">
                  <p><textarea class="form-control" type="text" v-model="edit_technicage.commentaireMedecin"  :disabled="!isBiologist"></textarea></p>
                  <button class="btn btn-info" @click="updateCommentaire" v-if="isBiologist">Mettre à jour commentaire médecin biologiste</button>
                  <br /><span style="margin-left: 5px" v-if="messageUpdateCommentaireMedecin !==  ''"><small>{{ messageUpdateCommentaireMedecin }}</small></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12">
              <button class="btn btn-info" @click="updateState" v-if="isBiologist">Renvoyer technicage au technicien</button>
              <span style="margin-left: 5px" v-if="messageReturnTechnician !==  ''"><small>{{ messageReturnTechnician }}</small></span>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 " style="margin-top: 10px">
      <div class="column w-100">
        <div class="card" >
          <div class="row">
            <!-- Stepper before result -->
            <stepper :step-headers="stepHeader" v-if="!agilentIsUpload || showProtocol" :go-result="agilentIsUpload()" @back-result="showProtocol = false" ></stepper>
            <!-- Display test result -->
            <tabs-plate-plan @disable-validation="accessDeniedValidation" @enable-validation="accessAllowValidation" @show-protocol="showProtocol = true"  v-else :tab-headers="tabsHeader"></tabs-plate-plan>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10">
        <!-- TODO: To manage state-->
        <button  class="btn me-2 btn-primary" @click="goOut(true)">Retour la liste des technicages</button>
        <button  class="btn me-2 btn-primary" @click="validationTechnicage(true)" :disabled="disableValidation">{{ displayValidation }}</button>
        <button class="btn me-2 btn-secondary" @click="validationTechnicage(false)" >Enregistrer</button>
      </div>
    </div>
    <modal-time-logout v-if="timeLogout"></modal-time-logout>
  </div>
  <spinner v-else></spinner>


</template>

<script>
import Technicage from "@/views/technicage/Technicage.vue";
import Equipment from "@/views/technicage/Equipment.vue";
import Navbar from "@/components/Navbar.vue";
import TableTestTechnicage from "@/views/technicage/Tests/TableTestTechnicage.vue";
import {mapGetters, mapMutations, mapActions} from "vuex";
import moment from "moment";
import TechnicageService from "@/services/technicage/technicage"
import ModalTimeLogout from "@/views/auth/ModalTimeLogout.vue";
import Spinner from "@/components/Spinner.vue";
import Stepper from "@/components/Stepper.vue";
import TabsPlatePlan from "@/views/technicage/Tests/TabsPlatePlan.vue";

export default {

  name: "EditTechnicage",
  components: {TabsPlatePlan, Stepper, Spinner, ModalTimeLogout, TableTestTechnicage, Navbar, Technicage, Equipment},
  props: ['id'],
  data(){
    return{
      stepHeader: [
        { name: 'Equipment', label: 'Consommables/Matériels',  component: 'Equipment', selected: true },
        { name: 'Workbench', label: 'Préparation paillasse' , component: 'Workbench', selected: false},
        { name: 'Technicage', label: 'Technicage', component: 'ProtocolTechnicage', selected: false},
      ],
      editTechnicage: null,
      messageReturnTechnician: '',
      showSpinner: false,
      disableValidation: false,
      messageUpdateCommentaireTechnicien: '',
      messageUpdateCommentaireMedecin: '',
      showProtocol: true,
      fontSizeIcon: 20,
      editDateReal: false,
      editNameTech: false,
      editTechnician: false,
      //Value use to modify technician
      technician: {id: null, name:null}
    }
  },
  setup(props) {
    return { ...props };
  },

  methods: {
    ...mapMutations('technicage', ['setEditTechnicage', 'setShowTest', 'setTestsTechnicage']),
    ...mapMutations('platePlan', ['setListPlatePlans']),
    ...mapActions('timer', ['startTimer', 'stopTimer', 'resetTimer']),
    ...mapActions('technicage', ['fetch_tests']),

    accessDeniedValidation(){
      this.disableValidation = true;
    },

    accessAllowValidation(){
      this.disableValidation = false;
    },

    goOut(updateState){
      if (updateState){
        let stateCode = 'EDITTECH';
        if (this.isBiologist) stateCode = 'EDITBIO';
        TechnicageService.update_state(this.edit_technicage.id,{state: stateCode, isBiologist: this.isBiologist}).then(() => {
          this.setEditTechnicage(null);
          this.setTestsTechnicage(null);
          this.setListPlatePlans(null);
          console.log('Etat du technicage changé');
        })
      }
      else{
        this.setEditTechnicage(null);
        this.setTestsTechnicage(null);
        this.setListPlatePlans(null);
      }
    },

    async updateCommentaire(){
      let idTechnicage = this.edit_technicage.id;
      let formData = new FormData();
      formData.append('commentaireTechnician', this.edit_technicage.commentaireTechnician !== null ? this.edit_technicage.commentaireTechnician : '');
      formData.append('commentaireMedecin',  this.edit_technicage.commentaireMedecin !== null ? this.edit_technicage.commentaireMedecin : '');
      await TechnicageService.update_commentaire(idTechnicage, formData).then(() => {
        if (this.isBiologist) this.messageUpdateCommentaireMedecin = 'Le commentaire médecin biologiste a bien été mis à jour'
        else this.messageUpdateCommentaireTechnicien = 'Le commentaire technicien a bien été mis à jour'
      });
    },

    updateState(){
      TechnicageService.update_state(this.edit_technicage.id, {state: "EDITTECH", isBiologist: this.isBiologist}).then(() => {
        console.log('Etat du technicage changé');
        this.messageReturnTechnician = 'Le technicage a été renvoyé au technicien';
      })
    },


    async validationTechnicage(goOut){
      this.showSpinner = true;
      const data = JSON.parse(JSON.stringify(this.edit_technicage));
      data.platePlans.forEach((element, index) => {
        data.platePlans[index].platePlanData = this.platePlans[index];
      })
      let idTechnicage = this.edit_technicage.id;
      await TechnicageService.update(idTechnicage, data, {
        headers:{
          'Content-Type': 'application/merge-patch+json'
        }
      }).then(() => {
        console.log('Technicage validé et envoyé chez le biologiste')
        this.goOut(goOut);
      })

      //La validation est possible que si on choisit 'Valider et fermer"
      if (this.goodForValidation()){
        let idTechnicage = this.edit_technicage.id;
        let status = 'VALBYTECH';
        let state = 'EDITBIO';
        if (this.isBiologist) status = 'VALBYBIOLOGIST';
        let formData = new FormData();
        formData.append('status', status);
        formData.append('state', state);
        formData.append('commentaireTechnician', this.edit_technicage.commentaireTechnician);
        formData.append('commentaireMedecin',  this.edit_technicage.commentaireMedecin);
        formData.append('isBiologist', this.isBiologist)
        await TechnicageService.validation(idTechnicage, formData).then(() => {
          console.log('Technicage validé et envoyé chez le biologiste')
          this.goOut(false);
        })
      }
      this.showSpinner = false;
    },

    goodForValidation(){
      let goodForValidation = true;
      if (this.tests_technicage != null){
        this.tests_technicage.forEach((item, index) => {
          if (item != null){
            if (this.isBiologist){
              if (!(item.status != null && item.status.code === 'VALIDATE' || item.status != null && item.status.code === 'ANOMALY')) {
                goodForValidation = false;
              }
            }
            else if (item.statusTechnician == null || (item.statusTechnician != null && item.statusTechnician.code == 'WIP')) {
              goodForValidation = false;
            }
          }
        })
      }
      else{
        goodForValidation = false;
      }
      return goodForValidation;
    },
    agilentIsUpload(){
      //PlatePlan[0] because technicage = 1 plaque
      let result = false;
      this.edit_technicage.platePlans[0].protocolFollows.forEach((item, index) => {
        if (item.description == 'UPLOAD' && item.done == true){
          result = true;
        }
      });
      return result;
    },

    changeTechnician(){
      this.edit_technicage.id_technician = this.technician.id;
      this.edit_technicage.name_technician = this.technician.name;
    }
  },

  computed: {
    ...mapGetters('technicage', ['edit_technicage', "tests_technicage", 'show_test']),
    ...mapGetters('auth', ['isBiologist']),
    ...mapGetters('config', ['allTechnician']),
    ...mapGetters('timer', ['time', 'timerRunning']),
    ...mapGetters('platePlan', ['platePlans']),
    moment() {
      return moment;
    },

    getStyleIcon(){
      return 'font-size: ' + this.fontSizeIcon + 'px;'
    },

    displayValidation (){

      if (this.goodForValidation()) return 'Valider technicage et fermer';
      else return 'Enregistrer technicage et fermer';

    },

    timeLogout(){

      //Affichage de la modal de déconnexion 1 minute avant la fin
      if (this.time >= (process.env.VUE_APP_TIME_EDITION_TECHNICAGE - 60)){
        return true;
      }
      return false;
    },

    tabsHeader(){
      let tabsHeader = [];
      this.edit_technicage.platePlans.forEach((item, index) => {
        let selected = false;
        if(index === 0) selected = true;
        tabsHeader.push({id: item.id, index: index, ref: item.ref, number: item.number, selected: selected});
      });
      return tabsHeader;
    },

  },

  beforeUnmount() {
    // Retirer les événements d'écoute lors de la destruction du composant
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("mousedown", this.resetTimer);
    window.removeEventListener("keypress", this.resetTimer);
    window.removeEventListener("scroll", this.resetTimer);
    window.removeEventListener("touchstart", this.resetTimer);
    this.resetTimer();
  },

  mounted() {
    if (this.agilentIsUpload()) this.showProtocol = false;
    this.resetTimer();
    this.stopTimer();
    this.startTimer(); // Initialiser le minuteur d'inactivité
    // Écouter les événements d'activité
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("mousedown", this.resetTimer);
    window.addEventListener("keypress", this.resetTimer);
    window.addEventListener("scroll", this.resetTimer);
    window.addEventListener("touchstart", this.resetTimer);

    //Init value technician to modify technician (value on select of technician)
    this.technician.id = this.edit_technicage.id_technician;
    this.technician.name = this.edit_technicage.name_technician;
  }
}
</script>

<style scoped>
  /* Float four columns side by side */
  .column {
    float: left;
    padding: 0 10px;
  }

  /* Remove extra left and right margins, due to padding */
  .row {margin: 0 -5px;}

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  /* Responsive columns */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }

  /* Style the counter cards */
  .card {
    padding: 16px;
    background-color: #ffffff;
  }

  textarea {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100px;
    white-space:nowrap;
  }
  .step {
    .bullet {
      border-radius: 50%;
      background-color: #26318d;
      color: white;
      font-weight: bold;
      width: 2rem;
      height: 2rem;
    }

    .title {
      font-size: small;
      color: #26318d;
      text-align: center;
      margin-top: 0.3rem;
    }
  }
</style>
